const whitelist = [
	{
		path: '/login',
		component: () => import('@/views/login/login.vue'),
		name: 'Login',
	},
	{
		path: '/lock',
		component: () => import('@/views/system/lock.vue'),
		name: 'Lock',
	},
	{
		path: '/:path(.*)',
		component: () => import('@/views/error/404.vue'),
		name: '404',
	},
]

export default whitelist
