import { createRouter, createWebHistory } from 'vue-router'
import { getQueryObject } from '@/utils/util'
import Layout from '@/layout/index.vue'
import Child from '@/layout/child.vue'
import whitelist from '@/router/whitelist'

export const constantRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/dashboard',
		children: [
			{
				path: 'dashboard',
				component: () => import('@/views/dashboard/index.vue'),
				name: 'Dashboard',
				meta: {
					title: '工作台',
					icon: '#icondashboard',
					affix: true,
				},
			},
			{
				path: 'profile',
				component: () => import('@/views/system/profile.vue'),
				name: 'Profile',
				meta: {
					title: '个人信息',
					icon: '#iconinfo',
					affix: false,
				},
			},
			{
				path: '/yiautos-micro-:name+/:pathMatch(.*)',
				component: Child,
			},
		],
	},
	{
		// 临时方案：兼容钉钉里的旧链接在新的主应用中无法识别的问题，后续钉钉链接更新成主应用中的新链接后，可以删除此路由
		path: '/#/qiankun/micro',
		beforeEnter: to => {
			const urlParams = getQueryObject(to.fullPath)
			const microParams = urlParams.src.split('://')[1]
			const [microName, microRoute] = microParams.split('/')
			delete urlParams.src
			return { path: `/${microName}/${microRoute}`, query: urlParams }
		},
	},
	...whitelist,
]
export const router = createRouter({
	history: createWebHistory(),
	routes: constantRoutes,
})
export function setupRouter(app) {
	app.use(router)
}
