import ElementPlus from 'element-plus'
// import ImLib from 'yiautos-web-im'
// import 'yiautos-web-im/lib/yiautos-web-im.css'
import * as ElIcons from '@element-plus/icons-vue'
import SvgIcon from '@/components/svg-icon/Index.vue' // svg component
// import { baseUrl } from '@/config/env'

const req = require.context('@/assets/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

export function registerGlobComp(app) {
	for (let iconName in ElIcons) {
		app.component(iconName, ElIcons[iconName])
	}
	app.component('SvgIcon', SvgIcon)
	app.use(ElementPlus)
	// app.use(ImLib, {
	// 	env: process.env.VUE_APP_CONFIG_ENV,
	// 	baseUrl,
	// })
}
