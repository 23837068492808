import request from '@/http'

export const getTopMenu = () =>
	request({
		url: '/api/blade-system/menu/top-menu',
		method: 'get',
	})

export const getRoutes = menuId =>
	request({
		url: '/api/blade-system/menu/routes',
		method: 'get',
		params: {
			menuId,
		},
	})
