const TimAppID = 1400508516

/**
 * 全局配置文件
 */
export default {
	title: process.env.VUE_APP_TITLE,
	enumKey: 'yiautos-nrp-enums',
	key: 'yiautos', //配置主键,目前用于存储
	clientId: 'saber', // 客户端id
	clientSecret: 'saber_secret', // 客户端密钥
	tokenHeader: 'Blade-Auth',
}
