<template>
	<div class="lock-area" @click="handleLock">
		<svg-icon icon-class="lock"></svg-icon>
		<el-dialog title="设置锁屏密码" v-model="dialogVisible" width="30%" append-to-body>
			<el-form :model="form" ref="formRef" label-width="80px">
				<el-form-item
					label="锁屏密码"
					prop="password"
					:rules="[{ required: true, message: '锁屏密码不能为空' }]"
				>
					<el-input v-model="form.password" placeholder="请输入锁屏密码" />
				</el-form-item>
			</el-form>
			<template #footer>
				<el-button type="primary" @click="handleSetLock">确 定</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { validatenull } from '@/utils/validate'
import { useAppStore } from '@/store/modules/app.js'
import { useRouter } from 'vue-router'

export default defineComponent({
	setup() {
		const appStore = useAppStore()
		const router = useRouter()
		const formRef = ref(null)
		const state = reactive({
			dialogVisible: false,
			form: {
				password: '',
			},
			handleSetLock() {
				console.log(formRef.value)
				formRef.value.validate(valid => {
					if (!valid) return
					appStore.setLock(state.form.password)
					router.push({ path: '/lock' })
				})
			},
			handleLock() {
				if (validatenull(appStore.lockPasswd)) {
					this.dialogVisible = true
				}
			},
		})

		return {
			formRef,
			...toRefs(state),
		}
	},
})
</script>
<style lang="less" scoped>
.lock-area {
	cursor: pointer;
	color: var(--text-color);
	transition: color 0.25s;
	.svg-icon {
		font-size: 22px;
	}
}
</style>
