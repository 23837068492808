import { loadMicroApp } from 'qiankun'
import MICRO_CONFIG, { ALL_MICRO_NAME_LIST } from '@/config/micro.js'
import { useAppStore } from '@/store/modules/app'
import { useTagStore } from '@/store/modules/tag'
import { router } from '@/router/index.js'

//  判断当前页签是否是微应用下的页面
const isMicroPath = function (path) {
	return !!ALL_MICRO_NAME_LIST.some(microName => {
		return path.includes(microName)
	})
}

// 查找当前匹配微应用
const findMicroAppByPath = path => {
	let ret = null

	for (let microName of ALL_MICRO_NAME_LIST) {
		if (path.startsWith(`/${microName}/`)) {
			ret = {
				id: microName,
				name: microName,
				entry: MICRO_CONFIG[microName],
				container: `#${microName}`,
				activeRule: `/${microName}`,
			}
			break
		}
	}

	return ret
}

export const loadApp = ({ path }) => {
	if (!isMicroPath(path)) return

	// 获取微应用配置
	const appStore = useAppStore()
	let microAppMap = appStore.microAppMap

	try {
		// 根据路径获取微应用config
		const appConfig = findMicroAppByPath(path)

		// 先判断微应用是否已加载过
		if (Object.hasOwnProperty.call(microAppMap, appConfig.name)) return

		microAppMap[appConfig.name] = loadMicroApp({
			...appConfig,
			configuration: {
				singular: true,
			},
			props: {
				$parentRouter: router,
			},
		})

		appStore.setMicroApp(microAppMap)
	} catch (err) {
		console.log('loadApp error => ', err)
	}
}

export const addTag = ({ path, query, meta }) => {
	const tagStore = useTagStore()
	const index = tagStore.visitedViews.findIndex(view => path.includes(view.path))

	if (index === -1) {
		const tag = {
			title: meta.title || query.name || '暂无标题',
			code: path,
			path: path,
			isActive: true,
			noCache: false,
			affix: meta.affix || false, // 只有 /dashboard 是不可关闭的
		}
		tagStore.addTag(tag)
	}
}
