const env = process.env
const ORIGIN = window.location.origin
const CHILD = '/child/'
const PREFIX = 'yiautos-micro-'

// 默认配置：新增一个配置会对 4 个环境都启用，如果某个环境的配置特殊，请到对应的 case 里单独配置。
const defaultNameList = [
	'comment',
	'psfs', // 把 psf 改名成 psfs，为了避免和别的 psfcrm、psftobmall、psfactivity、psfcrm 等冲突
	'psftobmall',
	'cms',
	'customer',
	'transaction',
	'finance',
	'psfactivity',
	'setting',
	'market',
	'scf',
	'scrm',
	'psfcrm',
	'carsource',
	'user',
	'product',
	'insurance',
	'chejiachacha',
	'admin',
]

const DEFAULT_MICRO_CONFIG = {}
defaultNameList.forEach(name => {
	DEFAULT_MICRO_CONFIG[PREFIX + name] = ORIGIN + `${CHILD}${PREFIX}${name}/`
})

// 针对不同环境的特殊配置
let ENV_MICRO_CONFIG = {}
console.log('VUE_APP_CONFIG_ENV', env.VUE_APP_CONFIG_ENV)
switch (env.VUE_APP_CONFIG_ENV) {
	case 'dev': {
		ENV_MICRO_CONFIG = {
			...ENV_MICRO_CONFIG,
			// 'yiautos-micro-finance': ORIGIN + '/child/yiautos-micro-finance/',
			// 'yiautos-micro-scf': ORIGIN + '/child/yiautos-micro-scf/',

			// // 本地开发的时候需要配置源为 localhost，特别注意端口号要和本地所启动的服务对应上
			// 'yiautos-micro-market': '//localhost:8081/child/yiautos-micro-market/',
			// // 'yiautos-micro-user': '//localhost:8082/child/yiautos-micro-user/',
			// 'yiautos-micro-insurance': '//localhost:8081/child/yiautos-micro-insurance/',
			// 'yiautos-micro-transaction': '//localhost:8081/child/yiautos-micro-transaction/',
		}
		break
	}
	case 'test':
		ENV_MICRO_CONFIG = {
			// 'yiautos-micro-insurance': '//localhost:8081/child/yiautos-micro-insurance/',
			// 'yiautos-micro-psfcrm': '//localhost:8082/child/yiautos-micro-psfcrm/',
			// 'yiautos-micro-user': ORIGIN + '/child/yiautos-micro-user/',
			// 'yiautos-micro-admin': '//localhost:8081/child/yiautos-micro-admin/',
			// 'yiautos-micro-comment': '//localhost:8081/child/yiautos-micro-comment/',
		}
		break
	case 'pre':
		ENV_MICRO_CONFIG = {
			// 'yiautos-micro-market': '//localhost:8081/child/yiautos-micro-market/',
			// 'yiautos-micro-transaction': '//localhost:8082/child/yiautos-micro-transaction/',
			// 'yiautos-micro-psfactivity': '//localhost:8082/child/yiautos-micro-psfactivity/',
		}
		break
	case 'prod':
		ENV_MICRO_CONFIG = {
			// 'yiautos-micro-product-gray': ORIGIN + '/child/yiautos-micro-product-gray/',
			// 'yiautos-micro-admin': '//localhost:8081/child/yiautos-micro-admin/',
		}
		// 生产环境用 product-gray，需要先去掉 product 配置
		// delete DEFAULT_MICRO_CONFIG['yiautos-micro-product']
		break
}

const EXPORT_MICRO_CONFIG = {
	...DEFAULT_MICRO_CONFIG,
	...ENV_MICRO_CONFIG,
}

console.log('EXPORT_MICRO_CONFIG', EXPORT_MICRO_CONFIG)

export const ALL_MICRO_NAME_LIST = Object.keys(EXPORT_MICRO_CONFIG)

export default EXPORT_MICRO_CONFIG
