<template>
	<router-view />
</template>

<style lang="less">
html,
body {
	height: 100%;
	margin: 0;
}
#app {
	height: 100%;
}
</style>
