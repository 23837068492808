import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { router } from '@/router'
import { useUserStore } from '@/store/modules/user'
import { addTag } from '@/micro'
import whiteList from '@/router/whitelist.js'
import { useAppStore } from '@/store/modules/app'

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
	NProgress.start()

	// 解决子应用路由内部跳转问题
	// http://wiki.itsmycar.cn/pages/viewpage.action?pageId=26981206#NRP/%E4%BA%91%E6%8E%A7%E5%B9%B3%E5%8F%B0%E5%BE%AE%E5%89%8D%E7%AB%AF%E5%AE%B9%E5%99%A8%E6%9E%B6%E6%9E%84%E5%8D%87%E7%BA%A7%E8%AE%BE%E8%AE%A1%E6%96%B9%E6%A1%88-%E5%BE%AE%E5%BA%94%E7%94%A8%E8%B7%AF%E7%94%B1%E8%B7%B3%E8%BD%AC%E5%A4%B1%E8%B4%A5
	// TODO 代码有问题
	if (!history.state.current) {
		Object.assign(history.state, { current: from.fullPath })
	}

	const userStore = useUserStore()
	const whitePaths = whiteList.map(route => route.path)
	if (userStore.token) {
		if (to.path === '/login') {
			next({ path: '/' })
			NProgress.done()
		} else if (whitePaths.includes(to.path) || to.name === '404') {
			const appStore = useAppStore()
			appStore.clearMicroApp()
			next()
		} else {
			// 当路径是旧链接的时候，会自动重定向到新的，所以该链接不需要打开一个 tag
			if (!to.path.includes('/#/qiankun/micro')) {
				addTag(to)
			}
			next()
		}
	} else {
		if (whitePaths.indexOf(to.path) !== -1 || to.name === '404') {
			next()
		} else {
			next(`/login`)
			// todo: 登录后路由重定向
			// next(`/login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// Finish progress bar
	NProgress.done()

	// set page title
	// document.title = getPageTitle(to.meta.title)
})
