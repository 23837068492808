import { defineStore } from 'pinia'
import { getStore, setStore, removeStore } from '@/utils/store'
import { getTopMenu, getRoutes } from '@/api/system/menu'
import { useUserStore } from '@/store/modules/user'
import { ElMessage } from 'element-plus'
import { router } from '@/router'

function resetPath(data) {
	const routes = data.map(item => {
		let path = item.path

		// 类似这种结构的路由：/system/user
		if (/^(\/[^-]*-?([^-]*)?){1,}$/.test(path)) {
			path = '/yiautos-micro-admin/' + path.split('/').slice(1).join('-')
		} else {
			path = path
				.replace('ymicros:/', '')
				.replace('ymicro:/', '')
				.replace('/yiautos-micro-psf/', '/yiautos-micro-psfs/')
		}
		item.path = path
		if (item.children && item.children.length > 0) {
			item.children = resetPath(item.children)
		}
		return item
	})

	return routes
}

export const usePermissionStore = defineStore({
	id: 'permission',
	state: () => ({
		menus: getStore({ name: 'menus' }) || [], // 顶部子应用菜单
		menuId: getStore({ name: 'menuId' }) || {}, // 当前激活的菜单
		routes: getStore({ name: 'routes' }) || [], // 侧边栏路由
	}),
	actions: {
		async setMenus() {
			try {
				const { code, data, msg } = (await getTopMenu()) || {}
				if (code !== 200) throw new Error(msg)
				if (!data || (data && data.length === 0)) {
					throw new Error('未获取到顶部菜单，请联系管理员配置')
				}
				this.menus = data
				setStore({ name: 'menus', content: data })
				this.setMenuId(data[0])
				return Promise.resolve(data)
			} catch (error) {
				ElMessage.error(error.message)
			}
		},
		clearMenus() {
			this.menus = []
			removeStore({ name: 'menus' })
		},
		setMenuId(menu) {
			this.menuId = menu
			setStore({ name: 'menuId', content: menu })
		},
		clearMenuId() {
			this.menuId = {}
			removeStore({ name: 'menuId' })
		},
		async setRoutes(menuId) {
			try {
				const { code, data, msg } = await getRoutes(menuId)
				if (code !== 200) throw new Error(msg)

				const userStore = useUserStore()

				// 没获取到菜单，需要退出重新登录
				if (!data) {
					ElMessage.warning('暂未获取到菜单，请重新登录')
					setTimeout(() => {
						userStore.logout()
						router.push('/login')
					}, 1500)
					return
				}
				const routes = resetPath(data)
				this.routes = routes
				setStore({ name: 'routes', content: routes })

				userStore.getButtons()
			} catch (error) {
				ElMessage.error(error.message)
			}
		},
		clearRoutes() {
			this.routes = []
			removeStore({ name: 'routes' })
		},
	},
})
