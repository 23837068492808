let baseUrl = ''
const env = process.env

const urlList = {
	dev: '//192.168.30.218',
	test: '//api.test.itsmycar.cn',
	pre: '//api.pre.itsmycar.cn',
	prod: '//api.yiautos.com',
}

baseUrl = urlList[env.VUE_APP_CONFIG_ENV]
let protocol = 'http:'
if (window.location.protocol.includes('s')) {
	protocol = 'https:'
}
baseUrl = `${protocol}${baseUrl}`

export { baseUrl }
