import { defineStore } from 'pinia'
import { getStore, setStore, removeStore } from '@/utils/store'
import { setToken, removeToken, setRefreshToken, removeRefreshToken } from '@/utils/cookies'
import { useAppStore } from './app'
import { useTagStore } from './tag'
import { usePermissionStore } from './permission'
import { getButtons } from '@/api/user'

export const useUserStore = defineStore({
	id: 'user',
	state: () => ({
		token: getStore({ name: 'token' }) || '',
		tokenExpire: getStore({ name: 'token-expire' }) || '',
		refreshToken: getStore({ name: 'refreshToken' }) || '',
		tenantId: getStore({ name: 'tenantId' }) || '',
		userInfo: getStore({ name: 'userInfo' }) || {},
		permission: getStore({ name: 'permission' }) || {},
		showIM: getStore({ name: 'permission' })?.chat_tim || false,
	}),
	actions: {
		setToken(token) {
			this.token = token
			setToken(token)
			setStore({ name: 'token', content: token })
		},
		clearToken() {
			this.token = ''
			removeToken()
			removeStore({ name: 'token' })
		},
		setExpire(expireTime) {
			this.tokenExpire = expireTime
			setStore({ name: 'token-expire', content: expireTime })
		},
		clearExpire() {
			this.tokenExpire = ''
			removeStore({ name: 'token-expire' })
		},
		setRefreshToken(refreshToken) {
			this.refreshToken = refreshToken
			setRefreshToken(refreshToken)
			setStore({ name: 'refreshToken', content: refreshToken })
		},
		clearRefreshToken() {
			this.refreshToken = ''
			removeRefreshToken()
			removeStore({ name: 'refreshToken' })
		},
		setTenantId(tenantId) {
			this.tenantId = tenantId
			setStore({
				name: 'tenantId',
				content: tenantId,
			})
		},
		clearTenantId() {
			this.tenantId = ''
			removeStore({ name: 'tenantId' })
		},
		setUserInfo(userInfo) {
			this.userInfo = userInfo
			setStore({
				name: 'userInfo',
				content: userInfo,
			})
		},
		clearUserInfo() {
			this.userInfo = {}
			removeStore({ name: 'userInfo' })
		},
		logout() {
			this.clearToken()
			this.clearExpire()
			this.clearRefreshToken()
			this.clearTenantId()
			this.clearUserInfo()

			const permissionStore = usePermissionStore()
			permissionStore.clearMenus()
			permissionStore.clearRoutes()
			permissionStore.clearMenuId()

			const tagStore = useTagStore()
			tagStore.deleteAllTags()

			const appStore = useAppStore()
			appStore.clearLock()
			appStore.clearMicroApp()
		},
		getButtons() {
			return new Promise(resolve => {
				getButtons().then(res => {
					const permission = res.data

					let result = []
					function getCode(list) {
						list.forEach(ele => {
							if (typeof ele === 'object') {
								const children = ele.children
								const code = ele.code
								if (children) {
									getCode(children)
								} else {
									result.push(code)
								}
							}
						})
					}

					getCode(permission)
					this.permission = {}
					result.forEach(ele => {
						this.permission[ele] = true
					})

					if (this.permission.chat_tim) {
						this.showIM = true
					}

					setStore({
						name: 'permission',
						content: this.permission,
					})
					resolve()
				})
			})
		},
	},
})
